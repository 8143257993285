<template>
  <div>
    <a-row>
      <a-col>
        <a-button @click="newItem">NEW</a-button>
      </a-col>
    </a-row>
    <a-row :gutter="12">
      
      <a-col :span="inEdit ? 16 : 24"> <div id="table"></div></a-col
      >
      <a-col :span="inEdit ? 8 : 0">
        <div
          style="
            padding: 12px;
            box-sizing: border-box;
            overflow-y: scroll;
            height: 100vh;
          "
        >
          <a-form
            id="components-form-demo-validate-other"
            :form="form"
            v-bind="formItemLayout"
            @submit="handleSubmit"
          >
            <a-form-item label="Nome">
              <a-input v-decorator="['name']"> </a-input>
            </a-form-item>
            <a-form-item label="Indirizzo">
              <a-input v-decorator="['indirizzo']"> </a-input>
            </a-form-item>
            <a-form-item label="Descrizione">
              <a-input v-decorator="['descrizione']"> </a-input>
            </a-form-item>
            <a-form-item label="Città">
              <a-input v-decorator="['citta']"> </a-input>
            </a-form-item>

            <a-form-item label="Select" has-feedback>
              <a-select
                v-decorator="['tipologia']"
                placeholder="Please select a country"
              >
                <a-select-option value="Ev1"> Pasticceria </a-select-option>
                <a-select-option value="Ristorev2ante">
                  Ristorante22
                </a-select-option>
              </a-select>
            </a-form-item>

            <!--  <a-form-item label="Lat">
              <a-input v-decorator="['lat']"></a-input>
            </a-form-item>

            <a-form-item label="Long">
              <a-input v-decorator="['lng']" />
            </a-form-item> -->

            <!--  <a-form-item label="Slider">
              <a-slider
                v-decorator="['slider']"
                :marks="{
                  0: 'A',
                  20: 'B',
                  40: 'C',
                  60: 'D',
                  80: 'E',
                  100: 'F',
                }"
              />
            </a-form-item>

            <a-form-item label="Radio.Group">
              <a-radio-group v-decorator="['radio-group']">
                <a-radio value="a"> item 1 </a-radio>
                <a-radio value="b"> item 2 </a-radio>
                <a-radio value="c"> item 3 </a-radio>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="Radio.Button">
              <a-radio-group v-decorator="['radio-button']">
                <a-radio-button value="a"> item 1 </a-radio-button>
                <a-radio-button value="b"> item 2 </a-radio-button>
                <a-radio-button value="c"> item 3 </a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item label="Checkbox.Group">
              <a-checkbox-group
                v-decorator="['checkbox-group', { initialValue: ['A', 'B'] }]"
                style="width: 100%"
              >
                <a-row>
                  <a-col :span="8">
                    <a-checkbox value="A"> A </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox disabled value="B"> B </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="C"> C </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="D"> D </a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="E"> E </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item> -->

            <!--  <a-form-item label="Rate">
              <a-rate
                v-decorator="['rate', { initialValue: 3.5 }]"
                allow-half
              />
            </a-form-item>

            <a-form-item
              label="Upload"
              extra="longgggggggggggggggggggggggggggggggggg"
            >
              <a-upload
                v-decorator="[
                  'upload',
                  {
                    valuePropName: 'fileList',
                    getValueFromEvent: normFile,
                  },
                ]"
                name="logo"
                action="/upload.do"
                list-type="picture"
              >
                <a-button> <a-icon type="upload" /> Click to upload </a-button>
              </a-upload>
            </a-form-item>

            <a-form-item label="Dragger">
              <div class="dropbox">
                <a-upload-dragger
                  v-decorator="[
                    'dragger',
                    {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                    },
                  ]"
                  name="files"
                  action="/upload.do"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p class="ant-upload-hint">
                    Support for a single or bulk upload.
                  </p>
                </a-upload-dragger>
              </div>
            </a-form-item> -->

            <a-form-item
              style="width: 100%"
              :wrapper-col="{ span: 24, offset: 0 }"
            >
              <a-button style="width: 100%" type="primary" html-type="submit">
                Salva
              </a-button>
            </a-form-item>
          </a-form>
        </div></a-col
      ></a-row
    >
  </div>
</template>




<script>
import Tabulator from "tabulator-tables";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "validate_other" });
  },
  computed: {
    events() {
      return this.$store.state.events;
    },
  },
  watch: {
    events() {
      this.table.setData(this.events);
    },
  },
  mounted() {
    this.table = new Tabulator(this.$el.querySelector("#table"), {
      height: 205, // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
      data: this.events, //assign data to table
      layout: "fitColumns", //fit columns to width of table (optional)
      autoColumns: true,
      rowClick: function (e, row) {
        this.inEdit = true;
        this.currentItem = row.getData();
        this.form.setFieldsValue({
          ...this.currentItem,
          /*           lat: +this.currentItem.location.geometry.coordinates[0],
          lng: +this.currentItem.location.geometry.coordinates[1], */
        });
        //trigger an alert message when the row is clicked
      }.bind(this),
    });
  },
  methods: {
    newItem() {
      this.inEdit = true;
    },
    updateData() {},
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.currentItem.key) {
            this.$db.ref(`/events/${this.currentItem.key}`).update({
              ...values,
              updateDate: new Date(),
            });
          } else {
            this.$events.push({
              ...values,
              updateDate: new Date(),
              creationDate: new Date(),
            });
          }
          console.log("Received values of form: ", values);
        }
      });
    },
    normFile(e) {
      console.log("Upload event:", e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
    add() {},
  },
  data() {
    return {
      inEdit: false,
      currentItem: {},
      labelPosition: "on-border",
    };
  },
};
</script>
<style>
#table {
  width: 100%;
}
</style>
